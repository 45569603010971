import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';

import { IRondeModel } from 'libs/shared-module/src/lib/models/cockpit/ironde.model';
import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { TableDataSource } from 'libs/shared-module/src/lib/providers/dataSource/table-data-source.provider';

@Component({
  selector: 'ahc-ironde',
  templateUrl: './ironde.component.html',
  styleUrls: ['./ironde.component.scss']
})
export class IRondeComponent implements OnInit, AfterViewInit {
  pageSize = 3;
  showFilter: boolean[] = [];
  totalPages: number;
  displayedColumns: string[] = [
    'id',
    'title',
    'validationDate',
    'questions',
    'answer',
    'anomaly'
  ];
  data: IRondeModel[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(public tableDataSource: TableDataSource<IRondeModel>) {}

  ngOnInit() {
    this.tableDataSource.init(this.pageSize, 'ironde');
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<IRondeModel>) => {
          this.data = result.items;
          this.paginator.length = result.totalSize;
        }
      );
    });
    this.paginator.page.pipe().subscribe(() => {
      this.tableDataSource.page(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }

  filter(column: string, query: string) {
    if (query) {
      this.tableDataSource.addFilter(column, query);
    } else {
      this.tableDataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.tableDataSource.removeSort(column);
    } else {
      this.tableDataSource.addSort(column, direction);
    }
  }
}
