import { Moment } from 'moment';

import { CellModel } from './cell.model';

export class EquipmentColumnModel {
  col: number;
  assetId: number;
  siteId: string;
  processTag: string;
  date: Moment;
  values: CellModel[];
  id: string;
}
