import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import * as Highcharts from 'highcharts';
import { GraphParameterModel } from 'libs/shared-module/src/lib/models/common/graph-parameter.model';
import { ScadaGraphParameterService } from 'libs/shared-module/src/lib/providers/scada-graph-parameter.provider';

@Component({
  selector: 'ahc-oil-degradation',
  templateUrl: './oil-degradation.component.html',
  styleUrls: ['./oil-degradation.component.scss']
})
export class OilDegradationComponent implements OnInit, AfterViewInit {
  @Input()
  equipmentId: string;
  @Input()
  parameter: string;
  containerId: Guid;
  public options: any;
  constructor(
    private graphService: ScadaGraphParameterService,
    private translate: TranslateService
  ) {
    this.containerId = Guid.create();
  }

  ngOnInit() {

    this.graphService
      .getOilParmaValues(this.equipmentId, this.parameter)
      .subscribe((graph: GraphParameterModel<Highcharts.Point>) => {
        this.options = {
          chart: {
            type: 'spline'
          },
          title: {
            text: this.translate.instant(
              'cockpit.oil_parameters.degradation_label'
            ),
            align: 'left',
            style: {
              color: '#375F9B',
              fontWeight: 'bold',
              fontFamily: 'roboto'
            }
          },
          subtitle: {
            text: '',
            align: 'left'
          },
          credits: {
            enabled: false
          },

          xAxis: {
            type: 'datetime',
            labels: {
              rotation: 45
            }
          },
          yAxis: {
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotBands: [
              {
                from: 0,
                to: 10,
                color: {
                  linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
                  stops: [
                    [0, 'rgb(252, 252, 252, 0.0001)'],
                    [1, ' rgb(249, 216, 216)']
                  ]
                }
              },
              {
                from: 10,
                to: 20,
                color: {
                  linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
                  stops: [
                    [0, 'rgb(252, 252, 252, 0.0001)'],
                    [1, ' rgb(249, 241, 216)']
                  ]
                }
              },
              {
                from: 65,
                to: 80,
                color: {
                  linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
                  stops: [
                    [0, ' rgb(249, 241, 216)'],
                    [1, 'rgb(252, 252, 252, 0.0001)']
                  ]
                }
              },
              {
                from: 80,
                to: 100,
                color: {
                  linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
                  stops: [
                    [0, ' rgb(249, 216, 216)'],
                    [1, 'rgb(252, 252, 252, 0.0001)']
                  ]
                }
              }
            ]
          },
          tooltip: {
            valueSuffix: ' m/s'
          },
          plotOptions: {},
          series: [
            {
              name: '',
              data: []
            },
            {
              name: '',
              dashStyle: 'solid',
              marker: {
                from: 1.3,
                to: 1.8,
                fillColor: '#00FF00'
              },
              color: 'rgba(0,0,0,0.25)',
              data: graph.values,
              zones: [
                {
                  value: [0 - 10],
                  marker: {
                    from: 1.3,
                    to: 1.8,
                    fillColor: '#00FF00'
                  }
                }
              ]
            }
          ]
        };
      });
  }
  ngAfterViewInit() {
    Highcharts.chart(this.containerId.toString(), this.options);
  }
}
