export const environment = {
  production: false,
  cognito: {
    amplify: {
      awsConfig: {
        Auth: {
          aws_project_region: 'eu-west-1',
          aws_cognito_region: 'eu-west-1',
          aws_user_pools_id: 'eu-west-1_8PBzPrBs3',
          aws_user_pools_web_client_id: '1s8s11onftf3l3no85jdp3sern',
          oauth: {},
          Analytics: {
            disabled: true
          }
        }
      }
    },
    multipassClientId: '2jd6dnhuuto553hg3mlb4tesk3',
    ssoUrl:
      // tslint:disable-next-line: max-line-length
      'https://ahc-dev2.auth.eu-west-1.amazoncognito.com/authorize?idp_identifier=multipass&response_type=token&client_id=2jd6dnhuuto553hg3mlb4tesk3&redirect_uri=[origin]/login/callback'
  },
  spreadJsLicense:
    // tslint:disable-next-line: max-line-length
    'Air Liquide,asset-health-center.airliquide.com|dev.asset-health-center.airliquide.com|pp.asset-health-center.airliquide.com,974894615524242#B0XKEWOkVjdhVXY5hVbH3WZuhWTOdXO6hUbMZ4dR56SQ9GVTRDd436ZEJ7YIJlZstkdFRXcKFDUh5mSGFkYTF6MnFVcGt4dm34QlBXaV54dph6LthlRFF5bSRXbidlTQdXMKFVasp4VvZlNmpmVMJmSxpEROVnROdzQOFHWWpnWvYEMJZkbYdkbFllUzlVSSB7bDtkWZFGZi3ScPJ7VLBzLYR6ZpV7RL9mQYllSwhlU4FjYkNVQDF6LkNDSXFjUjNDTGlXNjJTNoN7VSZndklXUuVkcBZjeUVDWvlmR5tiMutCUCFTUrUmbwo4bjZFMykkZTJndvQWN5kGb4dEeDdnUGVmRoVzUrhUaFVTcxI6UNBHNvAFVrxkR5YEZiojITJCLiI4QDZERzMjI0ICSiwCM6YzN7ATM9AjM0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsISNzYzMyEDI8ETMxkTMwIjI0ICdyNkIsISbvNmLlRWa5FXasJXah9iclRnblNWLoRHbhVGatQXZzNXYuAHcs46bj9SZklWdxlGbylWYuIXZ49WZj5Ca4xWYlhWL4V6czFmL6VGZs46bj9SZklWdxlGbylWYuIXZ49WZj5Ca4xWYlhWL4V6czFmI0IyctRkIsISZklWdxlGTgIXaBJiOiEmTDJCLiIDNyQjM5UTM6QTO8QzN9IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnZkWKtiWBV4aylFcwI7cH3GU58kTjdldWpnQohkYL36culVT6EWT98WU54EWP9mR5tSTytEZyFljjnm'
};
