import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ahc-oil-parameters',
  templateUrl: './oil-parameters.component.html',
  styleUrls: ['./oil-parameters.component.scss']
})
export class OilParametersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
