import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import * as Highcharts from 'highcharts/highstock';
import { GraphParameterModel } from 'libs/shared-module/src/lib/models/common/graph-parameter.model';
import { ScadaGraphParameterService } from 'libs/shared-module/src/lib/providers/scada-graph-parameter.provider';

@Component({
  selector: 'ahc-oil-parameter-graph',
  templateUrl: './oil-parameter-graph.component.html',
  styleUrls: ['./oil-parameter-graph.component.scss']
})
export class OilParameterGraphComponent implements OnInit, AfterViewInit {
  @Input()
  equipmentId: string;
  @Input()
  parameter: string;
  containerId: Guid;
  public options: any;
  constructor(
    private graphService: ScadaGraphParameterService,
    private translate: TranslateService
  ) {
    this.containerId = Guid.create();
  }

  ngOnInit() {
    this.graphService
      .getOilParmaValues(this.equipmentId, this.parameter)
      .subscribe((graph: GraphParameterModel<Highcharts.Point>) => {
        this.options = {
          chart: {
            zoomType: 'xy'
          },
          title: {
            text: this.translate.instant('cockpit.oil_parameters.label'),
            align: 'left',

            style: {
              color: '#375F9B',
              fontWeight: 'bold',
              fontFamily: 'roboto'
            }
          },
          subtitle: {
            text: '',
            align: 'left'
          },
          xAxis: {
            type: 'datetime',
            labels: {
              rotation: 45
            }
          },
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: '{value}°C',
                style: {
                  color: Highcharts.getOptions().colors[0]
                },
                resize: {
                  enabled: true
                }
              },
              title: {
                text: '',
                style: {
                  color: Highcharts.getOptions().colors[0],
                  fontWeight: 'thin',
                  fontFamily: 'roboto'
                }
              },
              plotLines: [
                {
                  color: 'rgba(220,0,0,0.5)',
                  value: 34,
                  width: 2,
                  label: {
                    text: 'HH - Parameter 1',
                    align: 'left'
                  }
                },
                {
                  color: 'rgba(220,0,0,0.5)',
                  value: 17,
                  width: 2,
                  label: {
                    text: 'LL - Parameter 1',
                    align: 'left'
                  }
                }
              ],

              opposite: true
            },
            {
              // Secondary yAxis

              title: {
                text: '',
                style: {
                  color: Highcharts.getOptions().colors[1]
                }
              },
              plotLines: [
                {
                  dashStyle: 'dot',
                  color: 'rgba(220,0,0,0.5)',
                  value: 7,
                  width: 2,
                  label: {
                    text: 'HH - Parameter 2',
                    align: 'left'
                  }
                },
                {
                  dashStyle: 'dot',
                  color: 'rgba(220,0,0,0.5)',
                  value: 1.5,
                  width: 2,
                  label: {
                    text: 'LL - Parameter 2',
                    align: 'left'
                  }
                }
              ],

              labels: {
                format: '{value} mb',
                style: {
                  color: Highcharts.getOptions().colors[1]
                }
              },
              opposite: true
            }
          ],
          tooltip: {
            shared: true
          },
          legend: {
            layout: 'vertical',
            align: 'left',
            x: 80,
            verticalAlign: 'top',
            y: 55,
            floating: true,
            backgroundColor: 'rgba(255,255,255,0.25)'
          },
          series: [
            {
              name: 'PARAMETER 1',
              type: 'spline',
              label: {
                enabled: false
              },
              yAxis: 1,
              data: graph.values,
              dashStyle: 'solid',
              color: 'rgba(0,0,0,0.25)',
              tooltip: {
                valueSuffix: ' mm'
              }
            },

            {
              name: 'PARAMETER 2',
              type: 'spline',
              label: {
                enabled: false
              },
              data: graph.values,
              dashStyle: 'shortdot',
              tooltip: {
                valueSuffix: ' °C'
              }
            }
          ],
          responsive: {
            rules: [
              {
                condition: {
                  Height: 400
                },
                chartOptions: {
                  legend: {
                    floating: false,
                    layout: 'horizontal',
                    align: 'left',
                    verticalAlign: 'top',
                    x: 0,
                    y: -20
                  },
                  credits: {
                    enabled: false
                  }
                }
              }
            ]
          }
        };
      });
  }
  ngAfterViewInit() {
    Highcharts.chart(this.containerId.toString(), this.options);
  }
}
